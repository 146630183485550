import * as turf from '@turf/turf'
import _ from 'lodash'
import { expression } from 'mapbox-gl/dist/style-spec/index.es.js'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      map: state => state.mapbox.map,
      isMeasuring: state => state.mapbox.isMeasuring
    }),
    ...mapGetters({
      layerObjs: 'mapbox/layerObjs',
      indLayerIds: 'mapbox/layerIds',
      sourceObjs: 'mapbox/sourceObjs',
      activeLayerObjs: 'mapbox/activeLayerObjs'
    }),
    activeLayers () {
      return [...this.$store.state.mapbox.activeLayers]
    },
    activeSources () {
      const visibleLayers = this.activeLayerObjs.filter(l => {
        const source = this.map.getSource(l.source)
        return (
          this.map.getLayoutProperty(l.id, 'visibility') === 'visible' &&
          source.type === 'geojson' &&
          source._data.features.length
        )
      })
      return visibleLayers.map(l => l.source)
    }
  },
  methods: {
    ...mapMutations({
      setActiveLayers: 'mapbox/setActiveLayers'
    }),
    ...mapActions({
      panToCoords: 'mapbox/mapPanToCoords',
      mapPanToBbox: 'mapbox/mapPanToBbox'
    }),
    setLayerVisibility (layerId, isVisible) {
      if (!this.map) {
        console.error(`Trying to set the visibility for "${layerId}" to "${String(isVisible)}" while the map is not initialized.`)
        return
      }

      const visibility = (isVisible) ? 'visible' : 'none'
      this.map.setLayoutProperty(layerId, 'visibility', visibility)

      // Keep track of 'visible' layers
      if (isVisible && !this.activeLayers.includes(layerId)) {
        this.activeLayers.push(layerId)
        this.setActiveLayers(this.activeLayers)
      } else if (!isVisible) {
        const update = this.activeLayers.filter(l => l !== layerId)
        this.setActiveLayers(update)
      }
    },
    /**
     * Find features near a MouseEvent.
     *
     * @param {Object} event A MouseEvent specifying location of the mouse etc.
     * @param {Array | undefined} layers Layers to search features in. To search all layers, pass undefined.
     * @param {Number} pixelMargin The distance (in pixels) within which to search for features.
     */
    closestFeatureFromMouseEvent (event, layers = this.indLayerIds, pixelMargin = 10) {
      const mouseLocationPixels = event.point
      const mouseLocationPoint = turf.point(event.lngLat.toArray())

      const bbox = [
        [mouseLocationPixels.x, mouseLocationPixels.y].map(c => c - pixelMargin),
        [mouseLocationPixels.x, mouseLocationPixels.y].map(c => c + pixelMargin)
      ]

      const bboxFeatures = this.map.queryRenderedFeatures(bbox, {
        layers
      })

      if (!(bboxFeatures && bboxFeatures.length)) {
        // No features within the specified bounding box
        return this.featureSnappedPointPair(null, turf.point(event.lngLat.toArray()))
      }

      const bboxFeaturesByIndex = _
        .chain(this.layerObjs)
        .reduce((accumulator, layer) => { // Accumulate features by z-index (of their layer)
          const bboxFeaturesForLayer = bboxFeatures.filter(feature => feature.layer.id === layer.id)
          accumulator[layer.properties.zIndex] = (accumulator[layer.properties.zIndex] || []).concat(bboxFeaturesForLayer)
          return accumulator
        }, {})
        .pickBy('length') // Remove z-indices without any features
        .value()

      const maxZIndex = _
        .chain(bboxFeaturesByIndex)
        .keys()
        .map(_.toInteger)
        .max()
        .value()

      try {
        const candidateFeatures = bboxFeaturesByIndex[maxZIndex]
        const closestPoints = _.map(candidateFeatures, feature => this.closestPoint(feature, mouseLocationPoint))
        const distances = _.map(closestPoints, point => turf.distance(point, mouseLocationPoint))
        const candidates = _.zip(candidateFeatures, closestPoints, distances)
        const closestFeature = _.minBy(candidates, '2')

        return this.featureSnappedPointPair(closestFeature[0], closestFeature[1])
      } catch (error) {
        return { error }
      }
    },
    featureSnappedPointPair (feature, snappedPoint) {
      return {
        feature,
        snappedPoint
      }
    },
    resolveExpression (expr, feature = null) {
      const resolvedExpression = expression.createExpression(expr, this.map.getStyle())
      if (feature) {
        return resolvedExpression.value.evaluate({ zoom: this.map.getZoom() }, feature)
      } else {
        return resolvedExpression.value.evaluate({ zoom: this.map.getZoom() })
      }
    },
    zoomToLayer (layerId) {
      // Check if a layer with that ID exists, stop when it doesn't
      const layerObj = this.layerObjs.find(l => layerId.toLowerCase().includes(l.id.toLowerCase()))
      if (!layerObj) {
        this.moveActiveLayersInView()
        return
      }

      // Get the FeatureCollection of the corresponding layer
      const FeatureCollection = this.map.getSource(layerObj.source)._data

      if (!FeatureCollection) {
        this.moveActiveLayersInView()
        return
      }

      // Use @/turf to create a bounding box around the Feature Collection
      const bbox = turf.bbox(FeatureCollection)
      this.mapPanToBbox(
        {
          bbox: [
            [bbox[0], bbox[1]], [bbox[2], bbox[3]]
          ],
          padding: {
            top: 2,
            right: 2,
            bottom: 2,
            left: 2
          }
        }
      )

      // Always set the layer's visibility to visible
      this.setLayerVisibility(layerObj.id, true)
    },
    moveActiveLayersInView () {
      if (!this.activeLayers.length) return
      const FeatureCollection = {
        type: 'FeatureCollection',
        features: []
      }

      // Filter sources which are active and are geojson
      const matchingSources = _.filter(this.sourceObjs, (src) => {
        return (
          src.type === 'geojson' &&
          _.includes(this.activeSources, src.properties.id)
        )
      })

      if (!matchingSources.length) return

      // Combine the feature collections of the found sources
      _.forEach(matchingSources, (src) => {
        FeatureCollection.features = _.concat(FeatureCollection.features, src.data.features)
      })

      // Use @/turf to create a bounding box around the Feature Collection
      const bbox = turf.bbox(FeatureCollection)
      this.mapPanToBbox(
        {
          bbox: [
            [bbox[0], bbox[1]], [bbox[2], bbox[3]]
          ],
          padding: {
            top: 2,
            right: 2,
            bottom: 2,
            left: 2
          }
        }
      )
    },
    attachHoverStateListener () {
      // this.map.setFeatureState(feature, { hover: true })
      this.map.on('mousemove', event => {
        if (this.isMeasuring) return

        // Get the feature which is currently hovered over
        const { feature: exclFeature } = this.closestFeatureFromMouseEvent(event)

        let applyHover = (exclFeature && !exclFeature.properties.point_count)

        if (exclFeature && exclFeature.layer.type === 'line') {
          // Set hover state for the excluded feature
          this.map.setFeatureState({
            id: exclFeature.id,
            source: exclFeature.source
          }, {
            hover: false // Do not show hover painting for this feature
          })
        } else applyHover = false

        // Find all rendered features
        let activeFeatures = this.map.queryRenderedFeatures(undefined, {
          layers: this.indLayerIds
          // filter: ['boolean', ['feature-state', 'hover'], !applyHover] // Only select features that have to be changed
        }).filter(({ layer }) => layer.type === 'line')

        if (exclFeature) {
          activeFeatures = activeFeatures.filter(f => {
            /**
             * Filter the feature to exclude from the rendered features
             * and filter the features which don't have to be changed
             */
            return (
              JSON.stringify(f.properties) !== JSON.stringify(exclFeature.properties) &&
              this.map.getFeatureState({
                id: f.id,
                source: f.source
              }).hover !== applyHover
            )
          })
        }

        // Set hover state for the rendered features
        activeFeatures.forEach(f => {
          this.map.setFeatureState({
            id: f.id,
            source: f.source
          }, {
            hover: applyHover
          })
        })
      })
    }
  }
}
